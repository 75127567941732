*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  color: #3d3d3d;
  font-family: "Equinor", Arial, serif;
  margin: 0;
}

a {
  color: #007079;
  text-decoration-line: underline;
}
